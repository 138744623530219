import Cookies from 'js-cookie'

// const testproxy = 'https://test-www.52imile.cn'
const testproxy = ''

// 检查手机号后四位
export const APICheckPhone = async (data) => {
  const url = `${testproxy}/hera/order/checkPhone`
  const formData = new FormData()
  Object.keys(data || {}).forEach((item) => {
    formData.append(item, data?.[item])
  })
  return window
    .fetch(url, {
      method: 'post',
      headers: { lang: Cookies.get('EXPRESS_LANG') },
      body: formData,
    })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return err
    })
}

// 获取文件完整地址根据文件名 -- 多个 1私有 2公有
export function APIgetDownloadPathsByfileName(data: string): Promise<any> {
  const url = `${testproxy}/flash/file/getUrlByFileKeys?fileKeys=${data}`
  return window
    .fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': `multipart/form-data;boundary=${new Date().getTime()}`,
      },
    })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return err
    })
}

// 检查运单 pod 图片校验
export const APICheckPod = async (data) => {
  const url = `${testproxy}/hera/order/check`
  const formData = new FormData()
  Object.keys(data || {}).forEach((item) => {
    formData.append(item, data?.[item])
  })
  return window
    .fetch(url, {
      method: 'post',
      headers: { lang: Cookies.get('EXPRESS_LANG') },
      body: formData,
    })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return err
    })
}
